import { TCurrencyCode } from 'types';

/**
 * @param locale
 * @param n
 * @returns String with the n formatted according to locale
 */
export function formatCurrencyLocalized(
  locale: string,
  n: number,
  currency: TCurrencyCode,
  signDisplay = false
): string {
  return new Intl.NumberFormat(locale, {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,

    // Pretend this number is a currency. The reason behind is because we are
    // formating our own currencies like "points".
    style: 'currency',

    // We use the currency euro currency code "EUR" to ensure the symbol is
    // the same for all languages, thus ensuring we can always substitute it
    // later with our custom one.
    currency,
    currencyDisplay: 'symbol',
    signDisplay: signDisplay === false ? 'auto' : 'exceptZero'
  }).format(n);
}

/**
 * @param locale
 * @param n
 * @returns String with the n formatted according to locale
 */
export function formatNumberLocalized(
  locale: string,
  n: number,
  signDisplay = false,
  enableDecimals = false
): string {
  const numberToFormat = enableDecimals
    ? n
    : n >= 0
    ? Math.floor(n)
    : Math.ceil(n);
  return new Intl.NumberFormat(locale, {
    maximumFractionDigits: enableDecimals ? 2 : 0,
    minimumFractionDigits: 0,
    signDisplay: signDisplay === false ? 'auto' : 'exceptZero'
  }).format(numberToFormat);
}

/**
 * Returns a string representing the percent number as a localized text.
 * @param locale locale to use while formating the string
 * @param percent the number value to represent as a percentage
 * @returns localized string representing the percentage number.
 */
export function formatPercentLocalized(
  locale: string,
  percent: number,
  signDisplay = false
): string {
  return new Intl.NumberFormat(locale, {
    style: 'percent',
    maximumFractionDigits: 0,
    minimumFractionDigits: 0,
    signDisplay: signDisplay === false ? 'auto' : 'exceptZero'
  }).format(normalizePercentageValue(percent) / 100);
}

export function normalizePercentageValue(percent: number): number {
  const normalizedValue =
    percent >= 0 ? Math.floor(percent) : Math.ceil(percent);

  if (Object.is(normalizedValue, -0)) {
    return 0;
  }

  return normalizedValue;
}

/**
 * Returns the current value in percentage in relation to the previous value.
 * @param currentValue
 * @param previousValue
 * @returns a normalized percentage value
 */
export function computePercentageTrend(
  currentValue: number,
  previousValue: number
): number {
  if (previousValue === 0 && currentValue > 0) {
    return 100;
  }

  if (previousValue === 0 && currentValue === 0) {
    return -100;
  }

  return normalizePercentageValue(
    ((currentValue - previousValue) / previousValue) * 100
  );
}

/**
 * Format number as unit of provided symbol
 * replaced.
 * @param locale
 * @param n
 * @returns
 */
export function formatCustomUnitLocalized(
  locale: string,
  n: number,
  unitSymbol: string,
  signDisplay = false,
  enableDecimals = false
): string {
  return `${formatNumberLocalized(
    locale,
    n,
    signDisplay,
    enableDecimals
  )}\u00A0${unitSymbol}`.trim();
}
