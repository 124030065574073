import { ISOCountryCode } from '@pmi.web/countries';
import { SupportedLanguage } from '@pmi.web/localization';

export const LANGUAGE_COUNTRY_CODES: {
  [lang in SupportedLanguage]: ISOCountryCode;
} = {
  [SupportedLanguage.Bulgarian]: ISOCountryCode.Bulgaria,
  [SupportedLanguage.Czech]: ISOCountryCode.Czechia,
  [SupportedLanguage.German]: ISOCountryCode.Germany,
  [SupportedLanguage.English]: ISOCountryCode.UnitedStatesAmerica,
  [SupportedLanguage.Spanish]: ISOCountryCode.Spain,
  [SupportedLanguage.Finnish]: ISOCountryCode.Finland,
  [SupportedLanguage.French]: ISOCountryCode.France,
  [SupportedLanguage.Hungarian]: ISOCountryCode.Hungary,
  [SupportedLanguage.Indonesian]: ISOCountryCode.Indonesia,
  [SupportedLanguage.Icelandic]: ISOCountryCode.Iceland,
  [SupportedLanguage.Italian]: ISOCountryCode.Italy,
  [SupportedLanguage.Japanese]: ISOCountryCode.Japan,
  [SupportedLanguage.Korean]: ISOCountryCode.SouthKorea,
  [SupportedLanguage.Norwegian]: ISOCountryCode.Norway,
  [SupportedLanguage.Dutch]: ISOCountryCode.Netherlands,
  [SupportedLanguage.Polish]: ISOCountryCode.Poland,
  [SupportedLanguage.Portuguese]: ISOCountryCode.Portugal,
  [SupportedLanguage.Russian]: ISOCountryCode.Russia,
  [SupportedLanguage.Swedish]: ISOCountryCode.Sweden,
  [SupportedLanguage.Thai]: ISOCountryCode.Thailand,
  [SupportedLanguage.Turkish]: ISOCountryCode.Turkey,
  [SupportedLanguage.Ukrainian]: ISOCountryCode.Ukraine,
  [SupportedLanguage.ChineseSimplified]: ISOCountryCode.China,
  [SupportedLanguage.ChineseTraditional]: ISOCountryCode.China,
  [SupportedLanguage.Vietnamese]: ISOCountryCode.Vietnam,
  [SupportedLanguage.Romanian]: ISOCountryCode.Romania,
  [SupportedLanguage.Estonian]: ISOCountryCode.Estonia,
  [SupportedLanguage.Lithuanian]: ISOCountryCode.Lithuania,
  [SupportedLanguage.Danish]: ISOCountryCode.Denmark
};
