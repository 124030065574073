import { ISOCountryCode, localizedCountryName } from '@pmi.web/countries';
import { CountryFlag } from '@pmi.web/react-country-flags';
import { useTranslation } from 'libs/translations';
import { useFormContext } from 'react-hook-form';
// eslint-disable-next-line no-restricted-imports
import { BiWorld } from 'react-icons/bi';
// eslint-disable-next-line no-restricted-imports
import { IoChevronDown } from 'react-icons/io5';

export function CountryFilter() {
  const { t, activeLanguage } = useTranslation();
  const { register, watch } = useFormContext();
  const countryFilterFlag = watch('byCountry');
  const sortedCountries = Object.values(ISOCountryCode)
    .map(countryCode => [
      countryCode,
      localizedCountryName(countryCode, activeLanguage)
    ])
    .sort((a, b) => a[1].localeCompare(b[1]));
  return (
    <fieldset>
      <legend>{t('Country')}</legend>
      <label htmlFor="byCountry">
        <div className="select-wrapper">
          {countryFilterFlag ? (
            <CountryFlag countryCode={countryFilterFlag} />
          ) : (
            <BiWorld size={16} className="text-silver" />
          )}
          <p className="flex-grow">
            {countryFilterFlag
              ? localizedCountryName(countryFilterFlag, activeLanguage)
              : t('All countries')}
          </p>
          <IoChevronDown size={16} />
          <select {...register('byCountry')}>
            <option value="">{t('All countries')}</option>
            {sortedCountries.map(([countryCode, countryName]) => (
              <option key={countryCode} value={countryCode}>
                {countryName}
              </option>
            ))}
          </select>
        </div>
      </label>
    </fieldset>
  );
}
