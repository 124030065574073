import { CountryFlag } from '@pmi.web/react-country-flags';
import { QualificationBadge } from 'components/badges';
import { FormattedUnitValue } from 'components/text';
import { Month } from 'libs/time';
import { useTranslation } from 'libs/translations';
import _ from 'lodash';
// eslint-disable-next-line no-restricted-imports
import { BiWorld } from 'react-icons/bi';
import { TUserSearchSorting } from 'services';
import { QualificationLevel } from 'types';
import { parseQualificationLevel } from 'utils';

import { useAnalyticsContext } from '../../contexts/AnalyticsContext';
import { ActiveFilter } from '../filter-active/ActiveFilter';
import { FilterSectionName } from '../filter-sections/FilterSectionName';

export function ActiveFiltersAndSorting() {
  const { t, activeLanguage } = useTranslation();
  const { filterParams, sortParams } = useAnalyticsContext();

  return (
    <ul className="flex flex-row flex-nowrap gap-xs overflow-x-auto py-sm">
      <li>
        <ActiveFilter filterSection={FilterSectionName.Period}>
          <span className="whitespace-nowrap">
            {Month.createOrThrow(filterParams.period).toLocaleString(
              activeLanguage
            )}
          </span>
        </ActiveFilter>
      </li>

      <li>
        <ActiveFilter filterSection={FilterSectionName.CountryAndPerformance}>
          {filterParams.byCountry ? (
            <div className="whitespace-nowrap w-max">
              <CountryFlag
                countryCode={filterParams.byCountry}
                withCountryName
              />
            </div>
          ) : (
            <div className="whitespace-nowrap flex flex-row flex-nowrap items-center">
              <BiWorld size={16} className="text-silver mr-xxs" />
              {t('All countries')}
            </div>
          )}
        </ActiveFilter>
      </li>

      <li>
        <ActiveFilter filterSection={FilterSectionName.Qualification}>
          {!_.isUndefined(filterParams.byQualificationFrom) ? (
            <>
              <QualificationBadge
                hideDetails
                qualification={
                  parseQualificationLevel(
                    filterParams.byQualificationFrom
                  ) as QualificationLevel
                }
              />
              <span>{' - '}</span>
            </>
          ) : (
            <span className="whitespace-nowrap flex flex-row flex-nowrap items-center">
              {t('Up to')}
            </span>
          )}
          <QualificationBadge
            hideDetails
            qualification={
              parseQualificationLevel(
                filterParams.byQualificationTo
              ) as QualificationLevel
            }
          />
        </ActiveFilter>
      </li>

      {(filterParams.byStructureVolumeFrom !== 0 ||
        filterParams.byStructureVolumeTo !== Infinity) && (
        <li>
          <ActiveFilter filterSection={FilterSectionName.StructureSales}>
            <FormattedUnitValue
              unit="points"
              value={filterParams.byStructureVolumeFrom ?? 0}
            />
            <span>{' - '}</span>
            <FormattedUnitValue
              unit="points"
              value={filterParams.byStructureVolumeTo ?? Infinity}
            />
          </ActiveFilter>
        </li>
      )}

      {filterParams.withCheckAssurance !== '' && (
        <li>
          <ActiveFilter filterSection={FilterSectionName.CountryAndPerformance}>
            {filterParams.withCheckAssurance === 'true'
              ? t('With Check Assurance')
              : t('Without Check Assurance')}
          </ActiveFilter>
        </li>
      )}

      {filterParams.newRegistrations !== '' && (
        <li>
          <ActiveFilter filterSection={FilterSectionName.CountryAndPerformance}>
            {filterParams.newRegistrations === 'true'
              ? t('New Registration')
              : t('Existing Team Partner')}
          </ActiveFilter>
        </li>
      )}

      {filterParams.withNewQualification !== '' && (
        <li>
          <ActiveFilter filterSection={FilterSectionName.CountryAndPerformance}>
            {filterParams.withNewQualification === 'true'
              ? t('New Qualification achieved')
              : t('No new Qualification achieved')}
          </ActiveFilter>
        </li>
      )}

      {filterParams.withSponsorActivity !== '' && (
        <li>
          <ActiveFilter filterSection={FilterSectionName.CountryAndPerformance}>
            {filterParams.withSponsorActivity === 'true'
              ? t('Active Sponsor')
              : t('Inactive Sponsor')}
          </ActiveFilter>
        </li>
      )}

      {filterParams.fitlineClubMembers !== '' && (
        <li>
          <ActiveFilter filterSection={FilterSectionName.CountryAndPerformance}>
            {filterParams.fitlineClubMembers === 'true'
              ? t('FitLine Club member')
              : t('No FitLine Club member')}
          </ActiveFilter>
        </li>
      )}

      {sortParams.sorting !== undefined && (
        <li>
          <ActiveFilter filterSection={FilterSectionName.SortBy}>
            <SortingText value={sortParams.sorting} />
          </ActiveFilter>
        </li>
      )}
    </ul>
  );
}

function SortingText(props: { readonly value: TUserSearchSorting }) {
  const { t } = useTranslation();
  const text = useTranslateSortFilter(props.value);

  return (
    <span className="whitespace-nowrap">
      {t('Sort by: {{text}}', { text })}
    </span>
  );
}

function useTranslateSortFilter(value: TUserSearchSorting) {
  const { t } = useTranslation();

  switch (value) {
    case TUserSearchSorting.ByStructureVolumeDesc:
      return t('Structure Sales (high to low)');
    case TUserSearchSorting.ByStructureVolumeAsc:
      return t('Structure Sales (low to high)');
    case TUserSearchSorting.ByNameAsc:
      return t('Name (A-Z)');
    case TUserSearchSorting.ByNameDesc:
      return t('Name (Z-A)');
    case TUserSearchSorting.ByHasCheckAssuranceDesc:
      return t('Check Assurance first');
    case TUserSearchSorting.ByHasCheckAssuranceAsc:
      return t('Check Assurance last');
    case TUserSearchSorting.ByQualificationDesc:
      return t('Qualification (high to low)');
    case TUserSearchSorting.ByQualificationAsc:
      return t('Qualification (low to high)');
    case TUserSearchSorting.ByQualificationLastMonthDesc:
      return t('Qualification last month (high to low)');
    case TUserSearchSorting.ByQualificationLastMonthAsc:
      return t('Qualification last month (low to high)');
    case TUserSearchSorting.ByRankDesc:
      return t('Rank (high to low)');
    case TUserSearchSorting.ByRankAsc:
      return t('Rank (low to high)');
    case TUserSearchSorting.ByOwnVolumeDesc:
      return t('Personal Sales (high to low)');
    case TUserSearchSorting.ByOwnVolumeAsc:
      return t('Personal Sales (low to high)');
    case TUserSearchSorting.BySixLevelVolumeDesc:
      return t('6-Level Sales (high to low)');
    case TUserSearchSorting.BySixLevelVolumeAsc:
      return t('6-Level Sales (low to high)');
    case TUserSearchSorting.BySponsorActivityDesc:
      return t('Sponsor Active first');
    case TUserSearchSorting.BySponsorActivityAsc:
      return t('Sponsor Active last');
  }
}
