import { RankBadge } from 'components/badges';
import { QUALIFICATIONS } from 'contants';
import { useTranslation } from 'libs/translations';
import { QualificationLevel } from 'types';

export function RankBadgeDescription() {
  const { t } = useTranslation();

  return (
    <>
      <h1>{t('Ranks')}</h1>
      <div className="grid grid-cols-[56px_minmax(100px,_1fr)]">
        <RankBadge hideDetails rank={QualificationLevel.Teampartner} />
        {QUALIFICATIONS[QualificationLevel.Teampartner].name}
        <RankBadge hideDetails rank={QualificationLevel.Manager} />
        {QUALIFICATIONS[QualificationLevel.Manager].name}
        <RankBadge hideDetails rank={QualificationLevel.SalesManager} />
        {QUALIFICATIONS[QualificationLevel.SalesManager].name}
        <RankBadge hideDetails rank={QualificationLevel.MarketingManager} />
        {QUALIFICATIONS[QualificationLevel.MarketingManager].name}
        <RankBadge
          hideDetails
          rank={QualificationLevel.InternationalMarketingManager}
        />
        {QUALIFICATIONS[QualificationLevel.InternationalMarketingManager].name}
        <RankBadge hideDetails rank={QualificationLevel.VicePresident} />
        {QUALIFICATIONS[QualificationLevel.VicePresident].name}
        <RankBadge
          hideDetails
          rank={QualificationLevel.ExecutiveVicePresident}
        />
        {QUALIFICATIONS[QualificationLevel.ExecutiveVicePresident].name}
        <RankBadge hideDetails rank={QualificationLevel.PresidentsTeam} />
        {QUALIFICATIONS[QualificationLevel.PresidentsTeam].name}
        <RankBadge hideDetails rank={QualificationLevel.SilverPresidentsTeam} />
        {QUALIFICATIONS[QualificationLevel.SilverPresidentsTeam].name}
        <RankBadge hideDetails rank={QualificationLevel.GoldPresidentsTeam} />
        {QUALIFICATIONS[QualificationLevel.GoldPresidentsTeam].name}
        <RankBadge
          hideDetails
          rank={QualificationLevel.PlatinumPresidentsTeam}
        />
        {QUALIFICATIONS[QualificationLevel.PlatinumPresidentsTeam].name}
        <RankBadge hideDetails rank={QualificationLevel.ChampionsLeague} />
        {QUALIFICATIONS[QualificationLevel.ChampionsLeague].name}
        <RankBadge
          hideDetails
          rank={QualificationLevel.SilverChampionsLeague}
        />
        {QUALIFICATIONS[QualificationLevel.SilverChampionsLeague].name}
        <RankBadge hideDetails rank={QualificationLevel.GoldChampionsLeague} />
        {QUALIFICATIONS[QualificationLevel.GoldChampionsLeague].name}
        <RankBadge
          hideDetails
          rank={QualificationLevel.PlatinumChampionsLeague}
        />
        {QUALIFICATIONS[QualificationLevel.PlatinumChampionsLeague].name}
      </div>
    </>
  );
}
