import { Cheque } from 'libs/icons/svg-icons/svgs/cheque';
import { Payment } from 'libs/icons/svg-icons/svgs/payment';
import { Payout } from 'libs/icons/svg-icons/svgs/payout';
import { PMCredit } from 'libs/icons/svg-icons/svgs/pm-credit';
import { PMPayPlus } from 'libs/icons/svg-icons/svgs/pm-pay-plus';
import { BonusPayoutMethod } from 'types';

interface PayoutOptionIconProps {
  readonly method: BonusPayoutMethod;
}

export const PayoutOptionIcon = ({ method }: PayoutOptionIconProps) => {
  if (method === BonusPayoutMethod.Cheque) {
    return <Cheque size={48} />;
  }

  if (method === BonusPayoutMethod.BankTransfer) {
    return <Payment size={48} />;
  }

  if (method === BonusPayoutMethod.PayQuicker) {
    return <PMPayPlus size={92} />;
  }

  if (method === BonusPayoutMethod.PmWallet) {
    return <PMCredit size={48} />;
  }

  return <Payout size={48} />;
};
