import { IS_PROD_BUILD } from 'contants';
import { setClarityTag } from 'libs/telemetry';
import _ from 'lodash';
import { Log, User, UserManager, UserManagerSettings } from 'oidc-client-ts';
import { fetchUserProfile } from 'services';
import { IUserProfile } from 'types';
import { parseBoolean } from 'utils';

class AuthenticationService {
  private _userManager: UserManager | undefined;
  private _user: User | undefined;
  private _userProfile: IUserProfile | undefined;

  constructor() {
    this._userManager = undefined;
    this._user = undefined;
    this._userProfile = undefined;
  }

  public async init(): Promise<void> {
    Log.setLogger(console);
    Log.setLevel(IS_PROD_BUILD ? Log.ERROR : Log.WARN);

    this._userManager = new UserManager(this.getOidcSettings());

    // Cleanup user details in memory after signout
    this._userManager.events.addUserSignedOut(() => {
      this._user = undefined;
      this._userProfile = undefined;
    });

    // Refresh in-memory copy of the user
    this._userManager.events.addUserLoaded(async u => {
      this._user = u;
      if (this._user?.profile.sub && this.isAllowedToUseThisOffice) {
        try {
          this._userProfile = await fetchUserProfile(this._user?.profile.sub);
        } catch (err) {
          // swallow
        }
      }
    });

    return Promise.resolve();
  }

  public get isSignedIn() {
    return this._user !== undefined;
  }

  public async startSignIn(currentState?: string) {
    if (_.isUndefined(this._userManager)) {
      return;
    }

    this._userManager.signinRedirect({ state: currentState });
  }

  public async finishSignIn(): Promise<string | undefined> {
    if (_.isUndefined(this._userManager)) {
      return Promise.reject();
    }

    const signedInUser = await this._userManager.signinCallback();

    // If successfully authenticated, restore window location
    if (signedInUser && !signedInUser.expired) {
      setClarityTag('tpid', [signedInUser.profile.sub]);

      let state = undefined;

      if (signedInUser.state && typeof signedInUser.state === 'string') {
        // Restore window location to the state value
        state = signedInUser.state;
      }

      this._user = signedInUser;
      if (this._user?.profile.sub && this.isAllowedToUseThisOffice) {
        try {
          this._userProfile = await fetchUserProfile(this._user?.profile.sub);
        } catch (err) {
          // swallow
        }
      }

      return Promise.resolve(state);
    }

    return Promise.reject();
  }

  public signout() {
    return this._userManager?.signoutRedirect();
  }

  get accessToken() {
    return this._user?.access_token;
  }

  get user() {
    return this._userProfile!;
  }

  get isAllowedToUseThisOffice() {
    // Only Teampartners are allowed to use this application.
    // If a Customer logs in to it, he should be redirected to the shop.
    const isCustomer = parseBoolean(this._user?.profile.is_customer);

    if (_.isUndefined(isCustomer)) {
      console.error(
        'Could not determinte if user is a customer. Maybe is_customer is missing?'
      );
      return false;
    }

    // return isCustomer === false;
    return true;
  }

  get isImpersonator(): boolean {
    try {
      return !_.isUndefined(
        JSON.parse(this._user?.profile.act as string).sub ?? undefined
      );
    } catch (err) {
      return false;
    }
  }

  private getOidcSettings(): UserManagerSettings {
    return {
      authority: process.env.REACT_APP_IDSRV_API ?? '',
      client_id: process.env.REACT_APP_IDSRV_CLIENT_ID ?? '',
      redirect_uri: `${window.location.origin}/${process.env.REACT_APP_IDSRV_REDIRECT_ROUTE}`,
      post_logout_redirect_uri: window.location.origin,
      response_type: 'code',
      scope: process.env.REACT_APP_IDSRV_SCOPE,
      loadUserInfo: true,
      automaticSilentRenew: true,
      // The library has some issues with its timers which can cause
      // multiple requests being fired to renew the token. Setting the
      // notification time to -1 seems to fix it ¯\_(ツ)_/¯
      accessTokenExpiringNotificationTimeInSeconds: -1,
      acr_values: 'tenant:pmi'
    };
  }
}

export default new AuthenticationService();
