import { QualificationBadge } from 'components/badges';
import { QUALIFICATIONS } from 'contants';
import { useTranslation } from 'libs/translations';
import { QualificationLevel } from 'types';

export function QualificationBadgeDescription() {
  const { t } = useTranslation();

  return (
    <>
      <h1>{t('Qualification levels')}</h1>
      <div className="grid grid-cols-[56px_minmax(100px,_1fr)]">
        <QualificationBadge
          hideDetails
          qualification={QualificationLevel.Teampartner}
        />
        {QUALIFICATIONS[QualificationLevel.Teampartner].name}
        <QualificationBadge
          hideDetails
          qualification={QualificationLevel.Manager}
        />
        {QUALIFICATIONS[QualificationLevel.Manager].name}
        <QualificationBadge
          hideDetails
          qualification={QualificationLevel.SalesManager}
        />
        {QUALIFICATIONS[QualificationLevel.SalesManager].name}
        <QualificationBadge
          hideDetails
          qualification={QualificationLevel.MarketingManager}
        />
        {QUALIFICATIONS[QualificationLevel.MarketingManager].name}
        <QualificationBadge
          hideDetails
          qualification={QualificationLevel.InternationalMarketingManager}
        />
        {QUALIFICATIONS[QualificationLevel.InternationalMarketingManager].name}
        <QualificationBadge
          hideDetails
          qualification={QualificationLevel.VicePresident}
        />
        {QUALIFICATIONS[QualificationLevel.VicePresident].name}
        <QualificationBadge
          hideDetails
          qualification={QualificationLevel.ExecutiveVicePresident}
        />
        {QUALIFICATIONS[QualificationLevel.ExecutiveVicePresident].name}
        <QualificationBadge
          hideDetails
          qualification={QualificationLevel.PresidentsTeam}
        />
        {QUALIFICATIONS[QualificationLevel.PresidentsTeam].name}
        <QualificationBadge
          hideDetails
          qualification={QualificationLevel.SilverPresidentsTeam}
        />
        {QUALIFICATIONS[QualificationLevel.SilverPresidentsTeam].name}
        <QualificationBadge
          hideDetails
          qualification={QualificationLevel.GoldPresidentsTeam}
        />
        {QUALIFICATIONS[QualificationLevel.GoldPresidentsTeam].name}
        <QualificationBadge
          hideDetails
          qualification={QualificationLevel.PlatinumPresidentsTeam}
        />
        {QUALIFICATIONS[QualificationLevel.PlatinumPresidentsTeam].name}
        <QualificationBadge
          hideDetails
          qualification={QualificationLevel.ChampionsLeague}
        />
        {QUALIFICATIONS[QualificationLevel.ChampionsLeague].name}
        <QualificationBadge
          hideDetails
          qualification={QualificationLevel.SilverChampionsLeague}
        />
        {QUALIFICATIONS[QualificationLevel.SilverChampionsLeague].name}
        <QualificationBadge
          hideDetails
          qualification={QualificationLevel.GoldChampionsLeague}
        />
        {QUALIFICATIONS[QualificationLevel.GoldChampionsLeague].name}
        <QualificationBadge
          hideDetails
          qualification={QualificationLevel.PlatinumChampionsLeague}
        />
        {QUALIFICATIONS[QualificationLevel.PlatinumChampionsLeague].name}
      </div>
    </>
  );
  return null;
}
