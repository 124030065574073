import { HelperModal } from 'components/dialogs';
import { LoadingSpinner } from 'components/spinners';
import {
  Invoice,
  InvoiceItem,
  InvoiceSection,
  InvoiceSectionDivider
} from 'features/invoices';
import { PII, usePII } from 'features/pii';
import { useCancelBasket } from 'features/shared-baskets';
import { BasketOwner } from 'features/virtual-warehouse/components/basket/BasketOwner';
import { useBoolean, useUser } from 'hooks';
import { trackEvent } from 'libs/telemetry';
import { triggerSuccessToast, triggerWarningToast } from 'libs/toasts';
import { useTranslation } from 'libs/translations';
import _ from 'lodash';
import { useEffect } from 'react';
// eslint-disable-next-line no-restricted-imports
import { FaTrashCan } from 'react-icons/fa6';
// eslint-disable-next-line no-restricted-imports
import {
  IoBarcodeSharp,
  IoCalendarOutline,
  IoPersonOutline,
  IoPricetagsOutline,
  IoTimeOutline
} from 'react-icons/io5';
import { IBasket, BasketStatus } from 'types';

import { BasketCosts } from './BasketCosts';
import { BasketLinkShare } from './BasketLinkShare';
import { BasketProducts } from './BasketProducts';

/**
 * @deprecated
 */
export function Basket(props: IBasket) {
  const { t, activeLanguage } = useTranslation();
  const { pii } = usePII();
  const { cancelBasket, error, isSuccess, isPending } = useCancelBasket();
  const [
    isCancelConfirmationDialogOpen,
    {
      setFalse: closeCancelConfirmationDialog,
      setTrue: openCancelConfirmationDialog
    }
  ] = useBoolean(false);

  const { data: userData } = useUser(props.basket.customerId);
  const wasProspect =
    (userData?.createdAt.getTime() ?? 0) > props.basket.createdOn.getTime();

  useEffect(() => {
    if (error) {
      triggerWarningToast(
        t(
          'Failed to cancel this basket. Try again again later or contact the support team.'
        )
      );
      console.error(error);
    }
  }, [error, t]);

  useEffect(() => {
    if (isSuccess) {
      triggerSuccessToast(t('The basket was successfuly canceled.'));
      closeCancelConfirmationDialog();
    }
  }, [closeCancelConfirmationDialog, isSuccess, t]);

  return (
    <>
      <Invoice>
        {props.basket.status !== BasketStatus.PaymentAuthorized &&
          props.basket.status !== BasketStatus.Canceled && (
            <BasketLinkShare
              href={props.checkoutUrl}
              onClick={(copyClick, socialShareAppClick) => {
                if (copyClick) {
                  trackEvent({
                    name: 'VirtualWarehouseBasket_CheckoutLinkCopyClick',
                    properties: {
                      context: 'Basket Details'
                    }
                  });
                } else if (!_.isUndefined(socialShareAppClick)) {
                  trackEvent({
                    name: 'VirtualWarehouse_BasketSocialShareClick',
                    properties: {
                      context: 'Basket Details',
                      appName: socialShareAppClick
                    }
                  });
                }
              }}
            />
          )}

        <InvoiceItem
          icon={<IoPersonOutline size={16} />}
          title={t('Recipient')}
          value={
            <div className="flex flex-row items-center gap-sm flex-wrap">
              <BasketOwner
                basketOwnerId={props.basket.customerId}
                basketCreadtedOn={props.basket.createdOn}
              />
              {wasProspect &&
                props.basket.status === BasketStatus.PaymentAuthorized && (
                  <p className="pmi-caption text-silver font-bold bg-lightGray px-xs py-xxs rounded-full">
                    {t('New customer since {{- registrationDate}}', {
                      registrationDate: pii(
                        userData?.createdAt.toLocaleDateString(activeLanguage),
                        'fixed-date'
                      )
                    })}
                  </p>
                )}
              {wasProspect &&
                (props.basket.status === BasketStatus.PaymentPending ||
                  props.basket.status === BasketStatus.Open) && (
                  <p className="pmi-caption text-silver font-bold bg-lightGray px-xs py-xxs rounded-full">
                    {t('Registration is in progress')}
                  </p>
                )}
              {wasProspect && props.basket.status === BasketStatus.Canceled && (
                <p className="pmi-caption text-silver font-bold bg-lightGray px-xs py-xxs rounded-full">
                  {t('Invalid registration')}
                </p>
              )}
            </div>
          }
        />

        <InvoiceItem
          title={t('Products')}
          icon={<IoPricetagsOutline size={16} />}
          value={
            <>
              <BasketProducts
                {...props}
                showPoints={userData?.type === 'BusinessBuilder'}
              />
              <InvoiceSectionDivider />
              <BasketCosts
                {...props}
                showPoints={userData?.type === 'BusinessBuilder'}
              />
            </>
          }
        />

        <InvoiceSection>
          {props.basket.status === BasketStatus.Open && (
            <InvoiceItem
              title={t('Expires on')}
              icon={<IoTimeOutline size={16} />}
              value={
                <PII
                  value={props.basket.autoDeleteOn.toLocaleDateTimeString(
                    activeLanguage,
                    'medium'
                  )}
                  type="fixed-date"
                />
              }
            />
          )}
          <InvoiceItem
            icon={<IoCalendarOutline size={16} />}
            title={t('Created on')}
            value={
              <PII
                value={props.basket.createdOn.toLocaleDateTimeString(
                  activeLanguage,
                  'medium'
                )}
                type={'fixed-date'}
              />
            }
          />
          <InvoiceItem
            icon={<IoBarcodeSharp size={16} />}
            title={t('Reference')}
            value={<PII value={props.basket.basketId} type="ids" />}
          />
        </InvoiceSection>

        {props.basket.status === BasketStatus.Open && (
          <div className="flex justify-end">
            <button
              className="flex flex-row gap-sm items-center w-fit text-error px-sm py-xs rounded-full transition-all hover:bg-error hover:text-white"
              onClick={() => {
                trackEvent({
                  name: 'VirtualWarehouse_BasketCancelation_Click'
                });
                openCancelConfirmationDialog();
              }}
            >
              <FaTrashCan size={14} />
              {t('Cancel')}
            </button>
          </div>
        )}
      </Invoice>

      {isCancelConfirmationDialogOpen && (
        <HelperModal
          onDismiss={() => {
            trackEvent({ name: 'VirtualWarehouse_BasketCancelation_Dismiss' });
            closeCancelConfirmationDialog();
          }}
          showCloseButton={false}
        >
          <h1>{t('Basket Cancelation')}</h1>
          <p>
            {t(
              "Are you sure you would like to cancel this basket? If the basket is canceled, it won't be valid anymore and your customer will not be able to buy your products."
            )}
          </p>
          <div className="flex flex-row flex-wrap items-center justify-center gap-md">
            <button
              disabled={isPending}
              className="px-sm py-xs rounded-md transition-all hover:bg-lightGray"
              onClick={() => {
                trackEvent({
                  name: 'VirtualWarehouse_BasketCancelation_Dismiss'
                });
                closeCancelConfirmationDialog();
              }}
            >
              {t('No, keep it open')}
            </button>

            <button
              disabled={isPending}
              className="flex flex-row gap-sm items-center w-fit text-error px-sm py-xs rounded-md transition-all hover:bg-error hover:text-white"
              onClick={() => {
                trackEvent({
                  name: 'VirtualWarehouse_BasketCancelation_Confirm'
                });
                cancelBasket(props.basket.basketId);
              }}
            >
              <FaTrashCan size={14} />
              {t('Yes, cancel this basket')}
            </button>
          </div>
          {isPending && (
            <div className="inset-0 absolute pmi-glass-white rounded-md flex items-center justify-center">
              <LoadingSpinner smallSize />
            </div>
          )}
        </HelperModal>
      )}
    </>
  );
}
