import { parseSupportedLanguageOrDefault } from '@pmi.web/localization';
// eslint-disable-next-line no-restricted-imports
import { useTranslation as useTranslationReactI18Next } from 'react-i18next';

export function useTranslation() {
  const {
    t,
    i18n: { language }
  } = useTranslationReactI18Next();

  return {
    t,
    activeLanguage: parseSupportedLanguageOrDefault(language)
  };
}
