import { SwitchButton } from 'components/buttons/SwitchButton';
import { Modal } from 'components/dialogs';
import { PageTitle } from 'components/layouts/page-title/PageTitle';
import { Disclaimer } from 'components/text';
import { IS_PWA } from 'contants';
import { useGlobalConfigsContext } from 'contexts';
import { AccountBackButton, InstallationInstructions } from 'features/account';
import { usePatchOfficeServerState } from 'hooks';
import { FeatureIcon, FeatureIconName } from 'libs/icons';
import { setOptionalTelementryConsent, trackEvent } from 'libs/telemetry';
import { triggerErrorToast, triggerSuccessToast } from 'libs/toasts';
import { useTranslation } from 'libs/translations';
import _ from 'lodash';
import { useEffect, useState } from 'react';

export function AccountAppSettingsRoute() {
  const { t } = useTranslation();
  const {
    pii,
    app: {
      office: {
        enableAnimations,
        collapseNavBar,
        enableOptionalDiagnostics,
        enableDecimals
      }
    }
  } = useGlobalConfigsContext();
  const { patchPreferences, isSuccess, isError } = usePatchOfficeServerState();

  const [isInstallationInstructionsOpen, setInstallationInstructionsOpen] =
    useState<boolean>(false);

  useEffect(() => {
    if (isSuccess) {
      triggerSuccessToast(t('Successfully updated your preferences!'));
    }
  }, [isSuccess, t]);

  useEffect(() => {
    if (isError) {
      triggerErrorToast(
        t(
          'Failed to save your preferences. The updates were reverted. Please try again later or contact the our support.'
        )
      );
    }
  }, [isError, t]);

  return (
    <>
      <PageTitle />
      <section className="space-y-md m-auto">
        <AccountBackButton />

        <div className="bg-white p-sm shadow-md rounded-md">
          <h2 className="text-primary">{t('Data collection & privacy')}</h2>

          <div className="flex flex-row items-center justify-between mt-sm">
            <p>{t('Essential diagnostic data')}</p>
            <SwitchButton disabled isOn={true} onClick={_.noop} />
          </div>
          <p className="pmi-caption text-silver mt-xs mr-[48px]">
            {t(
              "We gather required diagnostic data to keep the PM Office secure, up-to-date and performing as expected. This data doesn't include your name, messages or other personal content."
            )}
          </p>

          <div className="flex flex-row items-center justify-between mt-sm">
            <p>{t('Optional diagnostic data')}</p>
            <SwitchButton
              isOn={!!enableOptionalDiagnostics}
              onClick={async newValue => {
                trackEvent({
                  name: 'SettingsUpdate_OptionalTelementryConsent',
                  properties: {
                    switch: 'Optional telemetry consent',
                    isOn: newValue
                  }
                });

                await patchPreferences({
                  enableOptionalDiagnostics: newValue
                });

                setOptionalTelementryConsent(newValue);
              }}
            />
          </div>
          <p className="pmi-caption text-silver mt-xs mr-[48px]">
            {t(
              'Help us improve your PM Office experience by providing optional diagnostic and usage data. None of this data includes any personally identifiable information like your name, business data or other personal content.'
            )}
          </p>
        </div>

        <div className="bg-white p-sm shadow-md rounded-md">
          <h2 className="text-primary">{t('Accessibility settings')}</h2>
          <div className="flex flex-row items-center justify-between mt-sm">
            <p>{t('Reduce motion effects')}</p>

            <SwitchButton
              isOn={enableAnimations === false}
              onClick={async newValue => {
                trackEvent({
                  name: 'SettingsUpdate_EnableAnimations',
                  properties: {
                    switch: 'Enable animations',
                    isOn: !newValue
                  }
                });

                await patchPreferences({
                  enableAnimations: !newValue
                });
              }}
            />
          </div>
        </div>

        <div className="bg-white p-sm shadow-md rounded-md">
          <h2 className="text-primary">{t('Application settings')}</h2>
          <div className="flex flex-row items-center justify-between mt-sm">
            <p>{t('Collapse navigation bar (desktop)')}</p>
            <SwitchButton
              isOn={!!collapseNavBar}
              onClick={async newValue => {
                trackEvent({
                  name: 'SettingsUpdate_CollapseNavBar',
                  properties: {
                    switch: 'Collapse nav bar',
                    isOn: newValue
                  }
                });

                await patchPreferences({ collapseNavBar: newValue });
              }}
            />
          </div>
          <div className="flex flex-row items-center justify-between mt-sm">
            <p>{t('Round Numbers Automatically')}</p>
            <SwitchButton
              isOn={!enableDecimals}
              onClick={async newValue => {
                trackEvent({
                  name: 'SettingsUpdate_Round_Numbers_Automatically',
                  properties: {
                    switch: 'Round Numbers Automatically',
                    isOn: !newValue
                  }
                });
                await patchPreferences({ enableDecimals: !newValue });
              }}
            />
          </div>
        </div>

        <div className="bg-white p-sm shadow-md rounded-md">
          <h2 className="text-primary mb-xs">
            <FeatureIcon
              name={FeatureIconName.PrivacyGuard}
              size={20}
              className="inline-block align-text-bottom mr-xs"
            />
            {t('Demo Mode')}
          </h2>

          <Disclaimer
            message={t(
              'Please note that this protection is exclusive to your user interface, allowing you to confidently demonstrate the website without revealing your data to others.'
            )}
          />

          <div className="flex flex-row items-center justify-between mt-sm">
            <p className="inline-block">{t('Demo personal data')}</p>
            <SwitchButton
              isOn={!!pii.enableDemoPersonalData}
              onClick={newValue => {
                trackEvent({
                  name: 'SettingsUpdate_PrivacyGuardPersonalInformation',
                  properties: {
                    switch: 'Demo Mode: Safe Personal Information',
                    isOn: newValue
                  }
                });

                pii.setDemoPersonalData(newValue);
              }}
            />
          </div>
          <p className="pmi-caption text-silver mt-xs mr-[48px]">
            {t(
              'Protects your privacy by concealing personal data such as names, Team Partner IDs, email addresses, phone numbers, registration countries, addresses, Event meeting IDs, Event QR Codes, and other sensitive information belonging to you and your team.'
            )}
          </p>

          <div className="flex flex-row items-center justify-between mt-sm">
            <p className="inline-block">{t('Hide business data')}</p>
            <SwitchButton
              isOn={!!pii.enableDemoBusinessData}
              onClick={newValue => {
                trackEvent({
                  name: 'SettingsUpdate_PrivacyGuardBusinessInformation',
                  properties: {
                    switch: 'Demo Mode: Safe Demo Revenue',
                    isOn: newValue
                  }
                });

                pii.setDemoBusinessData(newValue);
              }}
            />
          </div>
          <p className="pmi-caption text-silver mt-xs mr-[48px]">
            {t(
              'Protects business data, including payouts, sales, and other pertinent business information related to you and your team.'
            )}
          </p>
        </div>

        {!IS_PWA && (
          <div className="bg-white p-sm shadow-md rounded-md">
            <h2 className="text-primary">{t('PM Office on your device')}</h2>
            <p className="my-sm">
              {t('PM Office is available as a standalone application.')}
            </p>
            <button
              onClick={() => setInstallationInstructionsOpen(true)}
              className="px-md py-sm bg-primary text-white rounded-full"
            >
              {t('How to install PM Office?')}
            </button>
            <Modal
              noPadding
              open={isInstallationInstructionsOpen}
              closeButtonLabel={t('Dismiss')}
              onClose={() => setInstallationInstructionsOpen(false)}
            >
              <InstallationInstructions />
            </Modal>
          </div>
        )}
      </section>
    </>
  );
}
