import { FormattedUnitValue } from 'components/text';
import { useGlobalConfigsContext } from 'contexts';
import { usePII } from 'features/pii';
import { useUser } from 'hooks';
import { Icons } from 'libs/icons';
import { useTranslation } from 'libs/translations';
import { NavLink } from 'react-router-dom';
import { BasketStatus, IBasket } from 'types';

import { BasketOwner } from '../basket/BasketOwner';
import { BasketStatusBadge } from '../basket/BasketStatusBadge';

export function BasketCard(basket: IBasket) {
  const { t, activeLanguage } = useTranslation();
  const { pii } = usePII();
  const {
    device: { supportsHover }
  } = useGlobalConfigsContext();

  const { data: userData } = useUser(basket.basket.customerId);
  const wasProspect =
    (userData?.createdAt.getTime() ?? 0) > basket.basket.createdOn.getTime();

  const basketStatus = <BasketStatusBadge {...basket} />;
  const basketDescription = (
    <p className="pmi-caption text-silver font-bold">
      {basket.basket.status === BasketStatus.Open && (
        <span className="block">
          {t('Follow up on your customer to complete the sale.')}
        </span>
      )}

      {basket.basket.status === BasketStatus.Canceled && (
        <>
          <span className="block">
            {t('This basket is no longer available for purchase.')}
          </span>
          {userData?.type === 'Prospect' && (
            <span className="block">{t('Invalid registration')}</span>
          )}
        </>
      )}

      {basket.basket.status === BasketStatus.PaymentPending && (
        <>
          {wasProspect && (
            <span className="block">{t('Registration is in progress')}</span>
          )}
          <span className="block">
            {t(
              "You're almost there! Make sure your customer completes the purchase so they can enjoy the benefits."
            )}
          </span>
        </>
      )}

      {basket.basket.status === BasketStatus.PaymentAuthorized && (
        <>
          {wasProspect && (
            <>
              <span className="block">
                {t('New customer since {{- registrationDate}}', {
                  registrationDate: pii(
                    userData?.createdAt.toLocaleDateString(activeLanguage),
                    'fixed-date'
                  )
                })}
              </span>
              <span className="block">
                {t(
                  "Well done! You've successfully sold one of the curated baskets and gained a new customer!"
                )}
              </span>
            </>
          )}
          {!wasProspect && (
            <span className="block">
              {t(
                'Congratulations on the successful sale of your curated basket!'
              )}
            </span>
          )}
        </>
      )}
    </p>
  );
  const basketOwner = (
    <BasketOwner
      basketOwnerId={basket.basket.customerId}
      basketCreadtedOn={basket.basket.createdOn}
    />
  );
  const basketPoints = basket.basket.status !== BasketStatus.Canceled && (
    <FormattedUnitValue unit="points" value={basket.basket.totals.points} />
  );

  return (
    <>
      <NavLink
        to={basket.basket.basketId}
        className={`w-full flex flex-row flex-nowrap gap-xs justify-between items-center p-sm border border-transparent ${
          supportsHover
            ? 'hover:bg-secondary hover:text-primary hover:rounded-md hover:border-active transition-all'
            : ''
        }`}
      >
        {/* LARGE SCREEN */}
        <div className="hidden w-0 lg:block lg:w-full">
          <div className="grid grid-cols-1 lg:grid-cols-[1fr_1fr_80px] w-full gap-sm">
            {/* <div className="flex flex-row lg:flex-col items-center lg:items-start gap-sm lg:gap-xs"> */}
            <div className="grid grid-cols-2 lg:grid-cols-1 gap-sm">
              {basketStatus}
              {basketOwner}
            </div>
            <div className="flex flex-row items-center gap-sm flex-wrap">
              {basketDescription}
            </div>

            <div className="flex items-center justify-end">{basketPoints}</div>
          </div>
        </div>

        {/* SMALL SCREEN */}
        <div className="block w-full lg:hidden lg:w-0 space-y-sm">
          <div className="w-full flex justify-between items-center">
            {basketStatus}
            {basketPoints}
          </div>
          {basketOwner}
          {basketDescription}
        </div>

        <Icons.Common.ChevronRight size={20} className="text-silver" />
      </NavLink>
    </>
  );
}
