export type CostType = 'gross' | 'net';
export type MicroMeasurementUnit = 'gr' | 'ml';
export type DiscountType = 'percentage' | 'amount';
const fitlineVoucherItemType = ['pmCredit', 'fitlineVoucher'];
export type FitlineVoucherItemType = (typeof fitlineVoucherItemType)[number];
export type BasketItemType = FitlineVoucherItemType | 'product' | 'voucher';

export function isFitlineVoucherItem(
  itemType: string
): itemType is FitlineVoucherItemType {
  return fitlineVoucherItemType.includes(itemType);
}
