import { FormattedUnitValue } from 'components/text';
import {
  InvoiceCostItem,
  InvoiceCosts,
  InvoiceItem,
  InvoiceProductList,
  InvoiceSectionDivider,
  InvoiceCostTotal
} from 'features/invoices';
import { InvoiceFitlineVoucher } from 'features/invoices/InvoiceFitlineVoucher';
import { useTranslation } from 'libs/translations';
import { useMemo } from 'react';
// eslint-disable-next-line no-restricted-imports
import { IoPricetagsOutline } from 'react-icons/io5';
import { isFitlineVoucherItem } from 'types/common';
import { IOrder } from 'types/orders';

import { OrderSourceBadge } from './OrderSourceBadge';
import { OrderStatusBadge } from './OrderStatusBadge';

export function OrderInformation(props: IOrder) {
  const { items, totals, status, source, delivery, additionalCharges } = props;
  const { t } = useTranslation();

  const fitlineVoucherItem = useMemo(() => {
    const voucher = items.find(i => isFitlineVoucherItem(i.type));
    if (!voucher) {
      return null;
    }
    return {
      articleNumber: voucher.articleNumber,
      currencyCode: voucher.cost.currencyCode,
      itemPrice: voucher.cost.displayPrice,
      name: voucher.name
    };
  }, [items]);

  return (
    <>
      <div className="flex flex-row items-center gap-sm">
        <OrderSourceBadge source={source} />
        <OrderStatusBadge status={status} />
      </div>

      <InvoiceItem
        icon={<IoPricetagsOutline size={16} />}
        title={t('Products')}
        value={
          <>
            <InvoiceProductList
              showPoints
              items={items
                .filter(i => !isFitlineVoucherItem(i.type))
                .map(i => ({
                  articleNumber: i.articleNumber,
                  currencyCode: i.cost.currencyCode,
                  itemPrice: i.cost.displayPrice,
                  points: i.points,
                  quantity: i.quantity,
                  name: i.name
                }))}
            />
            {fitlineVoucherItem && (
              <InvoiceFitlineVoucher item={fitlineVoucherItem} />
            )}
            <InvoiceSectionDivider />

            <InvoiceCosts>
              <InvoiceCostItem
                label={t('Subtotal')}
                value={
                  <FormattedUnitValue
                    unit={totals.currencyCode}
                    value={totals.itemsDisplayTotal}
                  />
                }
              />

              {delivery?.selected.cost && (
                <InvoiceCostItem
                  label={t('Shipping & Handling')}
                  value={
                    <FormattedUnitValue
                      unit={delivery.selected.cost.currencyCode}
                      value={delivery.selected.cost.displayPrice}
                    />
                  }
                />
              )}

              {additionalCharges && additionalCharges.length > 0 && (
                <InvoiceCostItem
                  label={t('Additional charges')}
                  value={
                    <FormattedUnitValue
                      unit={totals.currencyCode}
                      value={additionalCharges.reduce(
                        (acc, elem) => acc + (elem.cost?.displayPrice ?? 0),
                        0
                      )}
                    />
                  }
                />
              )}

              <InvoiceCostItem
                label={t('Tax')}
                value={
                  <FormattedUnitValue
                    unit={totals.currencyCode}
                    value={totals.totalTaxAmount}
                  />
                }
              />

              <InvoiceSectionDivider thick />

              <InvoiceCostTotal
                label={t('Total')}
                value={
                  <span className="font-bold text-xl">
                    <FormattedUnitValue
                      unit={totals.currencyCode}
                      value={totals.totalGross}
                    />
                  </span>
                }
              />

              {totals.points > 0 && (
                <>
                  <InvoiceCostTotal
                    label={t('Points')}
                    value={
                      <FormattedUnitValue unit="points" value={totals.points} />
                    }
                  />
                </>
              )}
            </InvoiceCosts>
          </>
        }
      />
    </>
  );
}
