import { FormattedUnitValue } from 'components/text';
// eslint-disable-next-line no-restricted-imports
import { IoStorefront } from 'react-icons/io5';

interface IInvoiceFitlineVoucher {
  readonly name?: string;
  readonly imgSrc?: string;
  readonly articleNumber: string;
  readonly currencyCode: string;
  readonly itemPrice: number;
}

interface IInvoiceFitlineVoucherProps {
  readonly item: IInvoiceFitlineVoucher;
}

export function InvoiceFitlineVoucher(props: IInvoiceFitlineVoucherProps) {
  return (
    <div className="flex flex-row items-center gap-xs mt-4">
      <div className="w-[56px] h-[56px] rounded flex-shrink-0 bg-cover bg-no-repeat bg-center bg-lightGray">
        {props.item.imgSrc ? (
          <img
            title="FitLine Voucher"
            src={props.item.imgSrc}
            className="rounded-md"
          />
        ) : (
          <IoStorefront size={24} className="text-silver m-auto h-full" />
        )}
      </div>
      <div className="flex-grow">
        <p className="line-clamp-1">FitLine Voucher</p>
        <p className="pmi-caption"></p>
      </div>
      <div className="grid grid-rows-2 text-right">
        <span></span>
        <span className="font-bold">
          <FormattedUnitValue
            unit={props.item.currencyCode}
            value={props.item.itemPrice}
          />
        </span>
      </div>
    </div>
  );
}
