import { Pagination } from 'components/pagination';
import { LoadingSpinner } from 'components/spinners';
import { Disclaimer } from 'components/text';
import { ProductItem } from 'features/shared-baskets/components/product-item/ProductItem';
import { useNewBasketContext } from 'features/shared-baskets/contexts/useNewBasketContext';
import { Icon, IconName } from 'libs/icons';
import { useTranslation } from 'libs/translations';
import _ from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';

import { ContinueButton } from './ContinueButton';
import { ProductSectionTitle } from './ProductSectionTitle';
import { useProductCatalog } from './useProductCatalog';

const DEBOUNCE_WAIT = 500;
const PAGE_SIZE = 10;

export function BasketProductCatalog() {
  const { register, watch } = useForm<{
    readonly search?: string;
  }>();
  const { t, activeLanguage } = useTranslation();
  const {
    basketOwnerId,
    shopId,
    isAffiliateDiscountEnabled,
    shouldShowPoints,
    dispatchStepEvent
  } = useNewBasketContext();

  const [pageNumber, setPageNumber] = useState<number>(1);
  const [search, setInternalSearch] = useState<string | undefined>(undefined);

  const setSearch = (search?: string) => {
    setInternalSearch(search);
    setPageNumber(1);
  };

  const { data, error, isLoading } = useProductCatalog(
    activeLanguage,
    basketOwnerId,
    pageNumber,
    PAGE_SIZE,
    search
  );

  const { vwInventory, productCatalog } = useMemo(() => {
    return {
      vwInventory: data?.vwInventory ?? [],
      productCatalog: data?.productCatalog
    };
  }, [data]);

  const searchPlaceholder = useMemo(() => {
    let randomProduct;

    if (vwInventory && vwInventory.length > 0) {
      randomProduct =
        vwInventory[Math.floor(Math.random() * vwInventory.length)];
    } else if (productCatalog && productCatalog.results.length > 0) {
      randomProduct =
        productCatalog.results[
          Math.floor(Math.random() * productCatalog.results.length)
        ];
    } else {
      return t('Search');
    }

    const articleNumber = randomProduct.articleNumber;
    const name = randomProduct.details?.attributes?.name
      ? randomProduct.details.attributes.name.value
      : randomProduct.articleNumber;

    return Math.round(Math.random()) === 0 ? name : articleNumber;
  }, [productCatalog, t, vwInventory]);

  const onSearchInputChanges = useMemo(
    () =>
      _.debounce((searchInput?: string) => {
        if (searchInput && searchInput.length >= 3) {
          setSearch(searchInput);
        } else {
          setSearch(undefined);
        }
      }, DEBOUNCE_WAIT),
    []
  );

  useEffect(() => {
    const subscription = watch(data => {
      onSearchInputChanges(data.search);
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [onSearchInputChanges, watch]);

  useEffect(() => {
    if (!data) {
      return;
    }

    if (data.shopId !== shopId) {
      dispatchStepEvent({
        type: 'set-shop-id',
        value: data.shopId
      });
    }

    if (data.isAffiliateDiscountEnabled !== isAffiliateDiscountEnabled) {
      dispatchStepEvent({
        type: 'set-affiliate-discount',
        value: { enabled: data.isAffiliateDiscountEnabled }
      });
    }
  }, [data, dispatchStepEvent, isAffiliateDiscountEnabled, shopId]);

  return (
    <>
      <div className="w-full">
        <form>
          <label className="border border-silver rounded-md flex flex-nowrap items-center">
            <Icon
              name={IconName.Search}
              size={20}
              className="text-silver p-xs"
            />
            <input
              placeholder={searchPlaceholder}
              className="rounded-md flex flex-row items-center italic pr-xs py-xs outline-none bg-white w-full"
              type="search"
              {...register('search')}
            />
          </label>
        </form>
      </div>

      {isLoading ? (
        <div className="w-full flex items-center justify-center mt-md">
          <LoadingSpinner smallSize />
        </div>
      ) : (
        <>
          {!_.isUndefined(error) && (
            <Disclaimer
              message={t(
                'Something went wrong. If the problem persists, please contact our support team.'
              )}
              type="error"
            />
          )}

          {vwInventory && vwInventory.length > 0 && (
            <div className="space-y-xs mt-md">
              <ProductSectionTitle
                title={t('Products in your Virtual Warehouse')}
              />
              <Disclaimer
                message={t(
                  'You can select more units than the ones you have in stock, offering you greater flexibility and streamlined inventory management.'
                )}
              />
              <div className="flex flex-wrap gap-y-md">
                {vwInventory?.map(p => {
                  const name = p.details?.attributes?.name
                    ? p.details.attributes.name.value
                    : p.articleNumber;

                  const imageSrc = p.details?.media.find(
                    m => m.type === 'image' && !_.isUndefined(m.url)
                  );

                  return (
                    <ProductItem
                      key={p.articleNumber}
                      virtualWarehouseStock={p.quantity}
                      name={name}
                      articleNumber={p.articleNumber}
                      imageSrc={imageSrc?.url}
                      currency={p.cost.currencyCode}
                      itemPrice={p.cost.final}
                      points={shouldShowPoints ? p.points : undefined}
                    />
                  );
                })}
              </div>
            </div>
          )}

          {productCatalog && productCatalog.results.length > 0 && (
            <div className="space-y-xs mt-md">
              {vwInventory && vwInventory.length > 0 && (
                <ProductSectionTitle title={t('FitLine Catalog')} />
              )}
              <div className="flex flex-wrap gap-y-md">
                {productCatalog.results.map(p => {
                  const name = p.details?.attributes?.name
                    ? p.details.attributes.name.value
                    : p.articleNumber;

                  const imageSrc = p.details?.media.find(
                    m => m.type === 'image' && !_.isUndefined(m.url)
                  );

                  return (
                    <ProductItem
                      key={p.articleNumber}
                      name={name}
                      articleNumber={p.articleNumber}
                      imageSrc={imageSrc?.url}
                      currency={p.details?.currencyCode}
                      itemPrice={p.details?.displayPrice}
                      points={shouldShowPoints ? p.details?.points : undefined}
                      inStock={p.inStock}
                    />
                  );
                })}
              </div>
            </div>
          )}

          {productCatalog && productCatalog.paginationInfo.totalPages > 1 && (
            <div className="flex justify-center pointer-events-auto my-sm">
              <Pagination
                currentPage={productCatalog.paginationInfo.page}
                onPageChange={pageNumber => setPageNumber(pageNumber)}
                totalPages={productCatalog.paginationInfo.totalPages}
              />
            </div>
          )}

          {productCatalog &&
            productCatalog.results.length === 0 &&
            vwInventory.length === 0 && (
              <div className="flex flex-col items-center justify-center gap-xs mt-md text-silver">
                <Icon name={IconName.Search} size={24} />
                <p>{t('No results found')}</p>
              </div>
            )}

          <div className="w-full flex justify-end mt-md sticky bottom-0 bg-white py-xs bg-opacity-90">
            <ContinueButton />
          </div>
        </>
      )}
    </>
  );
}
