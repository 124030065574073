import { AuthenticatedRoute } from 'components/AuthenticatedRoute';
import { ErrorHandler } from 'components/errors/ErrorHandler';
import { DefaultLayout } from 'components/layouts/DefaultLayout';
import { PopCardContextProvider } from 'components/popcards/PopCardContext';
import { ServiceBootstrapper } from 'components/ServiceBootstrapper';
import { WelcomeToPMOffice } from 'components/WelcomeToPMOffice';
import { GlobalConfigsProvider } from 'contexts';
import { ShareBasketMarketingPopup } from 'features/shared-baskets/components/marketing-popup/ShareBasketMarketingPopup';
import {
  AccountAppSettingsRoute,
  AccountLanguageRoute,
  AccountPayoutsRoute,
  AccountProfileRoute,
  AccountDocumentsRoute,
  AccountOrdersRoute,
  AccountRoute,
  CallbackRoute,
  EventsRoute,
  UnauthorizedRoute,
  DashboardRoute,
  DownlineRoute,
  DownlinePathToRoute,
  DownlineUserDashboardRoute,
  EventDetailsRoute,
  AccountOrderRoute,
  WhatsNewRoute,
  AnalyticsRoute,
  VirtualWarehouseRoute,
  VirtualWarehouseCommitmentsListRoute,
  VirtualWarehouseCommitmentIdRoute,
  AccountWalletRoute,
  SalesRoute,
  BasketsRoute,
  BasketIdRoute,
  CreateBasketRoute,
  PromocodeRoute
} from 'pages';
import {
  createBrowserRouter,
  Navigate,
  Outlet,
  RouterProvider
} from 'react-router-dom';

const routesConf = [
  {
    path: '/',
    element: (
      <AuthenticatedRoute>
        <GlobalConfigsProvider>
          <ServiceBootstrapper>
            <PopCardContextProvider>
              <Outlet />
              <WelcomeToPMOffice />
              <ShareBasketMarketingPopup />
            </PopCardContextProvider>
          </ServiceBootstrapper>
        </GlobalConfigsProvider>
      </AuthenticatedRoute>
    ),
    errorElement: <ErrorHandler fullscreen />,
    children: [
      {
        index: true,
        element: <Navigate to={'dashboard'} />
      },
      {
        path: 'dashboard',
        element: (
          <DefaultLayout>
            <DashboardRoute />
          </DefaultLayout>
        )
      },
      {
        path: 'analytics',
        element: (
          <DefaultLayout>
            <AnalyticsRoute />
          </DefaultLayout>
        )
      },
      {
        path: 'virtual-warehouse',
        element: (
          <DefaultLayout>
            <VirtualWarehouseRoute />
          </DefaultLayout>
        ),
        children: [
          {
            index: true,
            element: <Navigate to="commitments" />
          },
          {
            path: 'overview',
            element: <Navigate to={'..'} />
          },
          {
            path: 'inventory',
            element: <Navigate to={'..'} />
          },
          {
            path: 'commitments',
            element: <VirtualWarehouseCommitmentsListRoute />,
            children: [
              {
                path: ':commitmentId',
                element: <VirtualWarehouseCommitmentIdRoute />
              }
            ]
          },
          {
            path: 'baskets',
            element: <Navigate to={'/sales/baskets'} />
          }
        ]
      },
      {
        path: 'sales',
        element: (
          <DefaultLayout>
            <SalesRoute />
          </DefaultLayout>
        ),
        children: [
          {
            index: true,
            element: <Navigate to="baskets" />
          },
          {
            path: 'baskets',
            element: <BasketsRoute />,
            children: [
              {
                path: ':basketId',
                element: <BasketIdRoute />
              }
            ]
          }
        ]
      },
      {
        path: 'promocode',
        element: (
          <DefaultLayout>
            <PromocodeRoute />
          </DefaultLayout>
        )
      },
      {
        path: 'sales/createbasket',
        element: <CreateBasketRoute />
      },
      {
        path: 'downline',
        element: (
          <DefaultLayout>
            <DownlineRoute />
          </DefaultLayout>
        )
      },
      {
        path: 'downline/:encodedUsersSelectedPath',
        element: (
          <DefaultLayout>
            <DownlineRoute />
          </DefaultLayout>
        ),
        children: [
          {
            path: 'dashboard',
            element: <DownlineUserDashboardRoute />
          }
        ]
      },
      {
        path: 'downline/pathTo',
        element: (
          <DefaultLayout>
            <DownlinePathToRoute />
          </DefaultLayout>
        )
      },
      {
        path: 'events',
        element: (
          <DefaultLayout>
            <EventsRoute />
          </DefaultLayout>
        ),
        children: [
          {
            path: ':eventId',
            element: <EventDetailsRoute />
          }
        ]
      },
      {
        path: 'account',
        element: (
          <DefaultLayout>
            <AccountRoute />
          </DefaultLayout>
        )
      },
      {
        path: 'account/profile',
        element: (
          <DefaultLayout>
            <AccountProfileRoute />
          </DefaultLayout>
        )
      },
      {
        path: 'account/language',
        element: (
          <DefaultLayout>
            <AccountLanguageRoute />
          </DefaultLayout>
        )
      },
      {
        path: 'account/payouts',
        element: (
          <DefaultLayout>
            <AccountPayoutsRoute />
          </DefaultLayout>
        )
      },
      {
        path: 'account/app-settings',
        element: (
          <DefaultLayout>
            <AccountAppSettingsRoute />
          </DefaultLayout>
        )
      },
      {
        path: 'account/documents',
        element: (
          <DefaultLayout>
            <AccountDocumentsRoute />
          </DefaultLayout>
        )
      },
      {
        path: 'account/orders',
        element: (
          <DefaultLayout>
            <AccountOrdersRoute />
          </DefaultLayout>
        ),
        children: [
          {
            path: ':orderId',
            element: <AccountOrderRoute />
          }
        ]
      },
      {
        path: 'account/fitline-voucher',
        element: (
          <DefaultLayout>
            <AccountWalletRoute />
          </DefaultLayout>
        )
      },
      {
        path: 'whatsnew',
        element: (
          <DefaultLayout>
            <WhatsNewRoute />
          </DefaultLayout>
        )
      }
    ]
  },
  {
    path: '/callback',
    element: <CallbackRoute />,
    errorElement: <ErrorHandler fullscreen />
  },
  {
    path: '/401',
    element: <UnauthorizedRoute />
  },
  {
    path: '*',
    element: <Navigate to={'/'} />,
    errorElement: <ErrorHandler err={new Error('Page not found')} fullscreen />
  }
];

export function AppRouter() {
  const router = createBrowserRouter(routesConf);

  return <RouterProvider router={router}></RouterProvider>;
}
