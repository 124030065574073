import { FormattedUnitValue } from 'components/text';
import { RouteIcon, RouteIconName } from 'libs/icons';
import { useTranslation } from 'libs/translations';
import _ from 'lodash';
// eslint-disable-next-line no-restricted-imports
import { IoStorefront } from 'react-icons/io5';

import { QuantityController } from './QuantityController';

interface IProductItem {
  readonly name: string;
  readonly articleNumber: string;
  readonly currency?: string;
  readonly itemPrice?: number;
  readonly points?: number;
  readonly imageSrc?: string;
  readonly virtualWarehouseStock?: number;
  readonly inStock?: boolean;
}

export function ProductItem({
  imageSrc,
  articleNumber,
  name,
  virtualWarehouseStock,
  currency,
  itemPrice,
  points,
  inStock = true
}: IProductItem) {
  const { t } = useTranslation();

  return (
    <div className="p-xs flex flex-col flex-grow-1 basis-1/2 sm:basis-1/3 md:basis-1/4 lg:basis-1/5 relative">
      {virtualWarehouseStock && (
        <div className="absolute top-xs right-xs text-xs leading-none text-silver font-bold tracking-wider bg-white bg-active rounded-bl-2xl p-xs flex gap-xs flex-nowrap items-center">
          <RouteIcon name={RouteIconName.Sales} size={16} />
          {virtualWarehouseStock}x
        </div>
      )}
      <div className="relative aspect-square bg-lightGray">
        {imageSrc ? (
          <object data={imageSrc} type="image/png" className="h-full w-full">
            <IoStorefront size={24} className="text-silver m-auto h-full" />
          </object>
        ) : (
          <IoStorefront size={24} className="text-silver m-auto h-full" />
        )}

        {!inStock && (
          <span className="absolute left-1 top-1 pmi-caption border-2 rounded border-red-500 text-red-500 font-bold px-1 py-0.5">
            {t('Sold out')}
          </span>
        )}
      </div>
      <div className="my-xxs">
        <p className="line-clamp-1 pmi-caption">{name}</p>
        <p className="pmi-caption text-silver">{articleNumber}</p>
      </div>
      {!_.isUndefined(points) && (
        <FormattedUnitValue unit="points" value={points} />
      )}
      {!_.isUndefined(currency) && !_.isUndefined(itemPrice) && (
        <span className="font-bold">
          <FormattedUnitValue unit={currency} value={itemPrice} />
        </span>
      )}
      {inStock && (
        <div className="mt-xs">
          <QuantityController articleNumber={articleNumber} />
        </div>
      )}
    </div>
  );
}
