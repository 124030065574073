import { useTranslation } from 'libs/translations';
import { BonusPayoutMethod, DirectCashPayoutMethod } from 'types';

export function usePayoutsI18n() {
  const { t } = useTranslation();

  const translatePayoutOptionType = (
    optionType: BonusPayoutMethod | DirectCashPayoutMethod
  ) => {
    switch (optionType) {
      case BonusPayoutMethod.BankTransfer:
        return t('Wire transfer');
      case BonusPayoutMethod.Cheque:
        return t('Cheque');
      case BonusPayoutMethod.PayQuicker:
      case DirectCashPayoutMethod.PayQuicker:
        return t('PM Pay+');
      case BonusPayoutMethod.PmWallet:
      case DirectCashPayoutMethod.PmWallet:
        return 'FitLine Voucher';
      case BonusPayoutMethod.None:
      default:
        return t('Alternative Payout Method');
    }
  };

  const translatePayoutOptionDescription = (
    optionType: BonusPayoutMethod | DirectCashPayoutMethod
  ) => {
    switch (optionType) {
      case BonusPayoutMethod.BankTransfer:
        return t('Get the money directly to your bank account.');
      case BonusPayoutMethod.Cheque:
        return t('Receive your payment by mail with a cheque');
      case BonusPayoutMethod.PayQuicker:
      case DirectCashPayoutMethod.PayQuicker:
        return t('Your payout will be processed using this method.');
      case BonusPayoutMethod.PmWallet:
      case DirectCashPayoutMethod.PmWallet:
        return t('Use your FitLine Voucher balance for future purchases');
      case BonusPayoutMethod.None:
      default:
        return t('For more details, contact your subsidiary.');
    }
  };

  return { translatePayoutOptionType, translatePayoutOptionDescription };
}
