import { QualificationBadge } from 'components/badges';
import { useGlobalConfigsContext } from 'contexts';
import { PII, usePII } from 'features/pii';
import { TPII } from 'features/pii/TPII';
import { useTranslation } from 'libs/translations';
import { useMemo } from 'react';
import {
  QualificationLevel,
  TBusinessMetricProgressUnit,
  isValidISOCurrencyCode
} from 'types';
import {
  formatNumberLocalized,
  formatPercentLocalized,
  formatCustomUnitLocalized,
  formatCurrencyLocalized
} from 'utils';

interface IFormattedUnitValueProps {
  readonly value: number;
  readonly unit: TBusinessMetricProgressUnit;
  readonly signDisplay?: boolean;
}

export function FormattedUnitValue(props: IFormattedUnitValueProps) {
  const { activeLanguage } = useTranslation();
  const { isPrivacyGuardEnabled } = usePII();
  const {
    app: {
      office: { enableDecimals }
    }
  } = useGlobalConfigsContext();

  const { formatedValue, type } = useMemo(() => {
    if (props.unit === 'percent' || props.unit === 'percentage') {
      return {
        formatedValue: formatPercentLocalized(
          activeLanguage,
          props.value,
          props.signDisplay
        ),
        type: 'number' as TPII
      };
    }

    if (props.unit === 'count' || props.unit === 'points') {
      return {
        formatedValue: formatNumberLocalized(
          activeLanguage,
          props.value,
          props.signDisplay,
          enableDecimals
        ),
        type: (props.unit === 'points' ? 'points' : 'number') as TPII
      };
    }

    if (isValidISOCurrencyCode(props.unit)) {
      return {
        formatedValue: formatCurrencyLocalized(
          activeLanguage,
          props.value,
          props.unit,
          props.signDisplay
        ),
        type: 'currency' as TPII
      };
    }

    if (
      Object.values(QualificationLevel).includes(
        props.unit as QualificationLevel
      )
    ) {
      return {
        formatedValue: formatNumberLocalized(
          activeLanguage,
          props.value,
          props.signDisplay,
          enableDecimals
        ),
        type: 'number' as TPII
      };
    }

    return {
      formatedValue: formatCustomUnitLocalized(
        activeLanguage,
        props.value,
        props.unit,
        props.signDisplay,
        enableDecimals
      ),
      type: 'number' as TPII
    };
  }, [
    activeLanguage,
    props.signDisplay,
    props.unit,
    props.value,
    enableDecimals
  ]);

  return (
    <span className="whitespace-nowrap">
      {isPrivacyGuardEnabled ? (
        formatedValue
      ) : (
        <PII type={type} value={formatedValue} />
      )}
      {props.unit === 'points' && (
        <>
          {'\u00A0'}
          <span className="text-silver font-bold">Ⓟ</span>
        </>
      )}
      {Object.values(QualificationLevel).includes(
        props.unit as QualificationLevel
      ) && (
        <QualificationBadge qualification={props.unit as QualificationLevel} />
      )}
    </span>
  );
}
