import { PII } from 'features/pii';
import { FeatureIconName, Icon, IconName } from 'libs/icons';
import { useTranslation } from 'libs/translations';
import _ from 'lodash';
import { useState } from 'react';
import { IUserPayoutData } from 'types';
import { formatCurrencyLocalized } from 'utils';

import './WidgetPayouts.scss';
import { WidgetPayoutsDetails } from './WidgetPayoutsDetails';
import { Widget } from '../Widget';

export function WidgetPayouts(props: IUserPayoutData) {
  const { t, activeLanguage } = useTranslation();

  const [showCurrentValue, setShowCurrentValue] = useState<boolean>(false);

  if (
    _.isUndefined(props.payoutCurrency) ||
    _.isUndefined(props.payoutCurrentValue)
  ) {
    return null;
  }

  return (
    <Widget
      traceId="WidgetPayouts"
      title={t('Payouts')}
      detailedViewButtonLabel={t('History')}
      iconName={FeatureIconName.Payouts}
      content={
        <div className="flex flex-row justify-between items-center">
          <h2 className="font-app font-bold text-[18px] leading-[24px] sm:text-[24px] sm:leading-[36px]">
            {showCurrentValue && (
              <PII
                value={formatCurrencyLocalized(
                  activeLanguage,
                  props.payoutCurrentValue,
                  props.payoutCurrency
                )}
                type="currency"
              />
            )}

            {!showCurrentValue && '••••••'}
          </h2>

          <button
            className="payouts-value-toogle-button"
            title={showCurrentValue ? t('Hide') : t('Show')}
            onClick={() =>
              setShowCurrentValue(
                showCurrentValueState => !showCurrentValueState
              )
            }
          >
            <Icon
              name={showCurrentValue ? IconName.EyeHide : IconName.EyeShow}
              size={20}
            />
          </button>
        </div>
      }
      detailedView={<WidgetPayoutsDetails {...props} />}
    />
  );
}
