import { ISOCountryCode } from '@pmi.web/countries';
import {
  parseSupportedLanguageOrDefault,
  SupportedLanguage
} from '@pmi.web/localization';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import _ from 'lodash';
import { useSearchParams } from 'react-router-dom';
import {
  fetchPreferences,
  patchMePreferences,
  patchOfficePreferences
} from 'services';
import AuthenticationService from 'services/AuthenticationService';
import {
  IMeAreaPreferences,
  IOfficeUserPreferences,
  IUserServerState
} from 'types';

const DEFAULT_PREFERENCES: IUserServerState = {
  me: {
    preferedLanguage: SupportedLanguage.English
  },
  office: {
    enableAnimations: true,
    enableDecimals: false,
    collapseNavBar: false,
    enableOptionalDiagnostics: false,
    notificationsSeen: [],
    lastBonusPayoutUpdateDate: undefined,
    lastCheckAssuranceAchivementDate: undefined,
    welcomeMessageDisplayDate: undefined,
    welcomeVirtualWarehouseDisplayDate: undefined,
    welcomeNewShareBasketsDisplayDate: undefined
  }
};

export function useUserServerState() {
  const [params] = useSearchParams();
  const urlLanguage = parseSupportedLanguageOrDefault(params.get('lang') ?? '');
  const userCountry = AuthenticationService.user.countryCode ?? '';
  const { data: preferences, isPending } = usePreferences();

  const serverState: IUserServerState = _.defaultsDeep(
    preferences,
    { me: { preferedLanguage: urlLanguage } },
    userCountry === ISOCountryCode.India && {
      office: { enableDecimals: true }
    },
    DEFAULT_PREFERENCES
  );

  return isPending ? undefined : serverState;
}

export const PREFERENCES_QUERY_KEY = 'pmi.web.office__preferences';

function usePreferences() {
  const query = useQuery({
    queryKey: [PREFERENCES_QUERY_KEY],
    queryFn: fetchPreferences,
    retry: false
  });

  return { ...query, error: query.error ?? undefined };
}

export function usePatchMeServerState() {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: patchMePreferences,
    onMutate: async (variables: Partial<IMeAreaPreferences>) => {
      // Cancel any outgoing refetches to avoid overwriting this optimistic update
      await queryClient.cancelQueries({ queryKey: [PREFERENCES_QUERY_KEY] });

      // Preserve the current query value
      const oldQueryData = queryClient.getQueryData<IUserServerState>([
        PREFERENCES_QUERY_KEY
      ]);

      // New query value
      const newQueryData = _.defaultsDeep({ me: variables }, oldQueryData);

      queryClient.setQueryData<IUserServerState>(
        [PREFERENCES_QUERY_KEY],
        newQueryData
      );

      // Return the old query data so that we can revert in case of error
      return { oldQueryData };
    },

    // In case of error, revert the query data back to the previous state
    onError: (err, variables, context) => {
      console.error(err);
      if (context?.oldQueryData) {
        queryClient.setQueryData<IUserServerState>(
          [PREFERENCES_QUERY_KEY],
          context.oldQueryData
        );
      }
    },
    // Invalidate query data to trigger a refresh after success
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [PREFERENCES_QUERY_KEY] });
    }
  });

  return {
    patchPreferences: mutation.mutate,
    isPending: mutation.isPending,
    isSuccess: mutation.isSuccess,
    isError: mutation.isError,
    error: mutation.error ?? undefined,
    data: mutation.data
  };
}

export function usePatchOfficeServerState() {
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: patchOfficePreferences,
    onMutate: async (variables: Partial<IOfficeUserPreferences>) => {
      // Cancel any outgoing refetches to avoid overwriting this optimistic update
      await queryClient.cancelQueries({ queryKey: [PREFERENCES_QUERY_KEY] });

      // Preserve the current query value
      const oldQueryData = queryClient.getQueryData<IUserServerState>([
        PREFERENCES_QUERY_KEY
      ]);

      // New query value
      const newQueryData = _.defaultsDeep({ office: variables }, oldQueryData);

      queryClient.setQueryData<IUserServerState>(
        [PREFERENCES_QUERY_KEY],
        newQueryData
      );

      // Return the old query data so that we can revert in case of error
      return { oldQueryData };
    },

    // In case of error, revert the query data back to the previous state
    onError: (err, variables, context) => {
      console.error(err);
      if (context?.oldQueryData) {
        queryClient.setQueryData<IUserServerState>(
          [PREFERENCES_QUERY_KEY],
          context.oldQueryData
        );
      }
    },

    // Invalidate query data to trigger a refresh success
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [PREFERENCES_QUERY_KEY] });
    }
  });

  return {
    patchPreferences: mutation.mutate,
    isPending: mutation.isPending,
    isSuccess: mutation.isSuccess,
    isError: mutation.isError,
    error: mutation.error ?? undefined,
    data: mutation.data
  };
}
